import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from "aos";
//sections
export default {
  name: "meet",
  watch: {
    '$route'(newVal){
      if(newVal){
        this.fetchPageContent(this.$route.params.slug)
      }
    }
  },
  components:{
  },
  data(){
    return{

    }
  },
  created() {
    this.fetchPageContent(this.$route.params.slug)
  },
  computed:{
    ...mapGetters({
      pageContent: 'pages/pageContent'
    }),
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  methods:{
    ...mapActions({
      fetchPageContent: 'pages/GET_PAGE_CONTENT'
    }),
  }
}